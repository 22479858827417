import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { animateScroll } from "react-scroll"
import { media } from "src/styles/breakpoints"
import useWindowSize from "src/hooks/useWindowSize"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"

const Hero = ({ title, subtitle, buttonText, imageSharp }) => {
  const titleLines = title.split("\n")

  function onButtonClick() {
    const scrollTop = Math.min(window.innerHeight, window.innerWidth * 0.7)
    animateScroll.scrollTo(scrollTop, {
      duration: 900,
      smooth: "easeInOutQuart",
    })
  }

  return (
    <Wrapper windowHeight={useWindowSize().height}>
      <Background image={imageSharp.gatsbyImageData} alt="" />
      <Container>
        <Content>
          <Title>
            {titleLines.map((line, index) => (
              <TitleLine
                key={index}
                dangerouslySetInnerHTML={{ __html: line }}
              />
            ))}
          </Title>
          {subtitle && (
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          )}
          {buttonText && (
            <Button onClick={onButtonClick}>
              {buttonText} <Icon name="arrow-down" source="feather" />
            </Button>
          )}
        </Content>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 100vh;
  max-height: ${(p) => p.windowHeight}px;
  overflow: hidden;
  margin-bottom: 100px;
  position: relative;
  color: #fff;

  ${media.tablet} {
    margin-bottom: 160px;
  }

  ${media.desktop} {
    max-height: 70vw;
    margin-bottom: 205px;
  }
`

const Background = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 1.5s ${(p) => p.theme.easing.easeOutExpo};
`

const Container = styled(BaseContainer)`
  display: flex;
  align-items: center;
  height: 100%;
`

const Content = styled.div`
  position: relative;
  padding-top: 60px;
`

const Title = styled.h1`
  font-weight: 200;
  font-size: 35px;
  line-height: 1.32;
  letter-spacing: -0.02em;

  strong,
  b {
    font-weight: 500;
  }

  ${media.tablet} {
    font-size: 45px;
  }

  ${media.desktop} {
    font-size: 53px;
  }
`

const TitleLine = styled.span`
  display: block;

  &:nth-child(2) {
    transition-delay: 0.2s;
  }

  &:nth-child(3) {
    transition-delay: 0.4s;
  }

  ${media.tablet} {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: 1s ${(p) => p.theme.easing.easeOutExpo};

    .wf-poppinswf-n2-active.wf-poppinswf-n5-active & {
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }
`

const Subtitle = styled.p`
  visibility: hidden;
  opacity: 0;
  max-width: 30em;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 35px;
  background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  color: inherit;
`

const Button = styled.button`
  margin-top: 30px;
  margin-left: 4px;
  height: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 50px;
  letter-spacing: 0.1em;

  ${media.tablet} {
    visibility: hidden;
    opacity: 0;
    transition: 0.7s 1.2s ${(p) => p.theme.easing.easeOutQuad};

    .wf-poppinswf-n2-active.wf-poppinswf-n5-active & {
      visibility: visible;
      opacity: 1;
    }
  }
`

const Icon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-left: 10px;
  transition: transform 0.25s;

  ${Button}:hover & {
    transform: translateY(5px);
  }
`

export default Hero
