const slugify = require('slugify')

export default function getSlug(post) {
    if (post.slug) {
      return prepareSlug(post.slug)
    } else if (post.title) {
      return prepareSlug(post.title)
    }
    return null
}

function prepareSlug(slug) {
    if (isOldSlug(slug)) {
        return slug
    }
    return slugify(slug, {
    lower: true,
    trim: true,
    })
}

/**
 * We have two old slugs that were published before we started replacing spaces with dashes.
 * It would break any old links to update these so we are keeping them as is.
 */
function isOldSlug(slug) {
    const oldSlugs = [
    'recent-highlights-from-our-plant-based work',
    'tech to the rescue',
    ] 
    if (!slug) return false
    else return oldSlugs.includes(slug)
}

