import React, { useState, useRef } from "react"
import styled from "styled-components"
import anime from "animejs"
import { animateScroll } from "react-scroll"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import useWindowSize from "src/hooks/useWindowSize"
import { media, breakpoints } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"
import { SecondaryTitle } from "src/components/Typography"
import { potentialHeight } from "src/utils/helpers"

const ProgramList = ({ programs }) => {
  const [observed, setObserved] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const list = useRef()
  const detailsMargin = useWindowSize().width >= breakpoints.tablet ? 50 : 100

  function onItemClick(index) {
    const incomingDetails = getNode(index)
    const outgoingDetails = getNode(activeIndex)
    const animateIn = index !== activeIndex

    function getNode(nodeIndex) {
      return list.current.childNodes[nodeIndex * 2 + 1]
    }

    setActiveIndex(animateIn ? index : -1)

    if (animateIn) {
      incomingDetails.style.display = "block"
      anime({
        targets: incomingDetails,
        height: potentialHeight(incomingDetails),
        marginBottom: detailsMargin,
        duration: 500,
        delay: outgoingDetails ? 300 : 0,
        easing: "easeInOutQuart",
        begin: () => {
          setTimeout(
            () => {
              incomingDetails.classList.add("-active")
              const scrollTop = incomingDetails.offsetTop - 420
              animateScroll.scrollTo(scrollTop, {
                duration: 500,
                smooth: "easeInOutQuart",
              })
            },
            outgoingDetails ? 300 : 0
          )
        },
        complete: () => {
          incomingDetails.style.height = "auto"
        },
      })
    }

    if (outgoingDetails) {
      anime({
        targets: outgoingDetails,
        height: [potentialHeight(outgoingDetails), 0],
        marginBottom: 0,
        duration: 300,
        easing: "easeInOutQuart",
        begin: () => {
          outgoingDetails.classList.remove("-active")
        },
        complete: () => {
          outgoingDetails.style.display = "none"
        },
      })
    }
  }

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      } else {
        // setObserved(false)
      }
    },
    elementRef: list,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={list} className={observed ? " -observed" : ""}>
      {programs.map(({ icon, iconSource, title, subtitle, text }, index) => (
        <React.Fragment key={index}>
          <Program
            order={index % 2 ? index - 1 : index}
            className={index === activeIndex ? "-active" : ""}
            onClick={() => {
              onItemClick(index)
            }}
          >
            <ProgramNumber>{index + 1}</ProgramNumber>
            <ProgramIconWrapper>
              <ProgramIcon name={icon} />
            </ProgramIconWrapper>
            <ProgramTitle>{title}</ProgramTitle>
            <ProgramSubitle>{subtitle}</ProgramSubitle>
            <ReadMoreButton>
              <ReadMoreButtonInner>Learn more</ReadMoreButtonInner>
              <ReadMoreIcon name="chevron-down" source="feather" />
            </ReadMoreButton>
          </Program>
          <Details order={Math.floor(index / 2) * 2 + 1}>
            <DetailsInner>
              <DetailsText dangerouslySetInnerHTML={{ __html: text }} />
            </DetailsInner>
            <DetailsCaretWrap>
              <DetailsCaret />
            </DetailsCaretWrap>
          </Details>
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;

  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const Program = styled.article`
  order: ${(p) => p.order};
  margin-bottom: 70px;
  position: relative;
  cursor: pointer;
  user-select: none;

  > :not(button) {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    ${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  &:nth-of-type(1) {
    > :nth-child(1) {
      transition-delay: 0.15s;
    }

    > :nth-child(2) {
      transition-delay: 0.25s;
    }

    > :nth-child(3) {
      transition-delay: 0.35s;
    }

    > :nth-child(4) {
      transition-delay: 0.45s;
    }
  }

  &:nth-of-type(2) {
    > :nth-child(1) {
      transition-delay: 0.3s;
    }

    > :nth-child(2) {
      transition-delay: 0.4s;
    }

    > :nth-child(3) {
      transition-delay: 0.5s;
    }

    > :nth-child(4) {
      transition-delay: 0.6s;
    }
  }

  &:nth-of-type(3) {
    > :nth-child(1) {
      transition-delay: 0.45s;
    }

    > :nth-child(2) {
      transition-delay: 0.55s;
    }

    > :nth-child(3) {
      transition-delay: 0.65s;
    }

    > :nth-child(4) {
      transition-delay: 0.75s;
    }
  }

  &:nth-of-type(4) {
    > :nth-child(1) {
      transition-delay: 0.6s;
    }

    > :nth-child(2) {
      transition-delay: 0.7s;
    }

    > :nth-child(3) {
      transition-delay: 0.8s;
    }

    > :nth-child(4) {
      transition-delay: 0.9s;
    }
  }

  &:nth-of-type(5) {
    > :nth-child(1) {
      transition-delay: 0.75s;
    }

    > :nth-child(2) {
      transition-delay: 0.85s;
    }

    > :nth-child(3) {
      transition-delay: 0.95s;
    }

    > :nth-child(4) {
      transition-delay: 1.05s;
    }
  }

  &:nth-of-type(6) {
    > :nth-child(1) {
      transition-delay: 0.9s;
    }

    > :nth-child(2) {
      transition-delay: 1s;
    }

    > :nth-child(3) {
      transition-delay: 1.1s;
    }

    > :nth-child(4) {
      transition-delay: 1.2s;
    }
  }

  &:nth-of-type(7) {
    > :nth-child(1) {
      transition-delay: 1.05s;
    }

    > :nth-child(2) {
      transition-delay: 1.15s;
    }

    > :nth-child(3) {
      transition-delay: 1.25s;
    }

    > :nth-child(4) {
      transition-delay: 1.35s;
    }
  }

  &:nth-of-type(8) {
    > :nth-child(1) {
      transition-delay: 1.2s;
    }

    > :nth-child(2) {
      transition-delay: 1.3s;
    }

    > :nth-child(3) {
      transition-delay: 1.4s;
    }

    > :nth-child(4) {
      transition-delay: 1.5s;
    }
  }

  ${media.tablet} {
    width: 50%;
    margin-bottom: 30px;

    &:nth-of-type(2n + 1) {
      padding-right: 25px;
    }

    &:nth-of-type(2n) {
      padding-left: 25px;
    }

    &:nth-of-type(n + 3) {
      margin-top: 80px;
    }
  }

  ${media.desktop} {
    &:nth-of-type(2n + 1) {
      padding-right: 0;
    }

    &:nth-of-type(2n) {
      padding-left: 0;
    }
  }
`

const ProgramNumber = styled.span`
  width: 200px;
  position: absolute;
  top: -55px;
  left: calc(50% - 100px);
  font-size: 200px;
  line-height: 1;
  color: rgba(12, 104, 116, 0.05);
  pointer-events: none;
  transition: color 0.15s;

  ${Program}:hover & {
    color: rgba(12, 104, 116, 0.07);
  }
`

const ProgramIconWrapper = styled.div`
  margin-bottom: 22px;
`

const ProgramIcon = styled(BaseIcon)`
  width: 24px;
  height: 24px;
  color: #46cad8;
`

const ProgramTitle = styled(SecondaryTitle)`
  max-width: 16em;
  margin: 0 auto 20px;
  /* hyphens: auto; */
`

const ProgramSubitle = styled.p`
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  font-size: 15px;
  /* line-height: 1.6; */
  color: ${(p) => p.theme.color.textLight};

  ${media.desktopMedium} {
    max-width: 420px;
  }
`

const ReadMoreButton = styled.button`
  display: inline-block;
  opacity: 0;
  height: 30px;
  padding: 0 20px;
  position: relative;
  transform: scale(0.9);
  background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 0.15s;

  &::before {
    opacity: 0;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(-20deg, #179cc2, #37d0cd);
    border-radius: 3px;
    transition: opacity 0.15s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  ${Program}:hover & {
    opacity: 1;
    transform: none;
  }

  ${Program}.-active & {
    opacity: 0;
  }
`

const ReadMoreButtonInner = styled.span`
  position: relative;
`

const ReadMoreIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-left: 10px;
  margin-right: -7px;
  position: relative;
`

const Details = styled.div`
  display: none;
  order: ${(p) => p.order};
  width: calc(50% + 90px * 2);
  max-width: 100%;
  height: 0;
  margin-top: -70px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background: #fff;
  }

  ${media.tablet} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const DetailsInner = styled.div`
  visibility: hidden;
  opacity: 0;
  padding: 30px;
  transition: 0.1s ${(p) => p.theme.easing.easeInOutQuart};

  ${Details}.-active & {
    visibility: visible;
    opacity: 1;
    transition-duration: 0.4s;
    transition-delay: 0.1s;
  }

  ${media.tablet} {
    padding: 60px;
  }

  ${media.desktop} {
    padding: 70px 90px;
  }
`

const DetailsText = styled.div`
  position: relative;
  z-index: 1;
  text-align: left;
  font-size: 15px;
  /* line-height: 1.6; */
  color: ${(p) => p.theme.color.textLight};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 27em;
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 17px;
    color: #000;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 50px;
    }
  }
`

const DetailsCaretWrap = styled.div`
  width: 20px;
  height: 11px;
  overflow: hidden;
  position: absolute;
  top: -8px;
  left: calc(50% - 10px);
  z-index: 2;

  ${media.tablet} {
    left: 80px;

    div${Details}:nth-of-type(even) & {
      left: auto;
      right: 80px;
    }
  }
`

const DetailsCaret = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 15px;
  left: 3px;
  transform: rotate(45deg);
  background: #fff;
  border: solid #4ecdd6;
  border-width: 3px 0 0 3px;
  transition: 0.3s ${(p) => p.theme.easing.easeInOutQuart};

  ${Details}.-active & {
    top: 4px;
  }
`

export default ProgramList
