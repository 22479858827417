import React, { useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import { SecondaryTitle } from "src/components/Typography"
import BaseIcon from "src/components/Base/BaseIcon"

const mediaLogos = [
  {
    name: "BBC",
    src: "bbc.svg",
    height: 14,
  },
  {
    name: "The Times",
    src: "the-times.svg",
    height: 14,
  },
  {
    name: "Daily Main Online",
    src: "mail-online.svg",
    height: 14,
  },
  {
    name: "BBC Ukraine",
    src: "bbc-ukraine.png",
    height: 14,
  },
  {
    name: "Delfi",
    src: "delfi.png",
    height: 13,
  },
  {
    name: "Postimees",
    src: "postimees.svg",
    height: 13,
  },
  {
    name: "TV 2",
    src: "tv2.svg",
    height: 14,
  },
  {
    name: "Politiken",
    src: "politiken.svg",
    height: 10,
  },
  {
    name: "DR",
    src: "dr.svg",
    height: 14,
  },
  {
    name: "TVN",
    src: "tvn.svg",
    height: 24,
  },
  {
    name: "Telewizja Polska",
    src: "tvp.svg",
    height: 16,
  },
  {
    name: "Polsat",
    src: "polsat.svg",
    height: 20,
  },
  {
    name: "TV3",
    src: "tv3.svg",
    height: 23,
  },
  {
    name: "Znaj.ua",
    src: "znaj-ua.svg",
    height: 12,
  },
  {
    name: "FEM",
    src: "fem.svg",
    height: 17,
  },
  {
    name: "Woman Magazine",
    src: "woman-magazine.png",
    height: 19,
  },
  {
    name: "LRT",
    src: "lrt.svg",
    height: 25,
  },
]

const SectionAchievementsStats = ({ socialMedia, traditionalMedia }) => {
  const stats = [
    {
      title: "Social media followers",
      icon: "thumbs-up",
      number: socialMedia,
    },
    {
      title: "Traditional media reach",
      icon: "radio",
      number: traditionalMedia,
    },
  ]

  const logos = useRef()
  const [logosLoadedCount, setLogosLoadedCount] = useState(0)
  const mediaLogosGroup = (groupIndex) => {
    return (
      <MediaLogosGroup className="media-logos-group">
        {mediaLogos.map(({ name, src, height }, index) => (
          <MediaLogo
            src={require(`src/assets/images/logos/media/${src}`).default}
            height={height}
            alt={name}
            title={name}
            key={index}
            loading="lazy"
            onLoad={() => {
              logoOnLoad(index, groupIndex)
            }}
          />
        ))}
      </MediaLogosGroup>
    )
  }

  function logoOnLoad(index, groupIndex) {
    if (groupIndex === 0) {
      const count = logosLoadedCount + 1
      setLogosLoadedCount(count)
      if (count === mediaLogos.length) {
        setLogoGroupWidth()
      }
    }
  }

  function setLogoGroupWidth() {
    const width = logos.current.childNodes[1].offsetWidth
    const groups = logos.current.querySelectorAll(".media-logos-group")
    groups.forEach((group) => {
      group.style.width = width + "px"
    })
    groups[1].style.left = -width + "px"
    groups[2].style.left = width + "px"
  }

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        logos.current.classList.add("-animation-init")
      }
    },
    elementRef: logos,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper>
      <Title>Our reach</Title>
      <Items>
        {stats.map(({ title, icon, number }, index) => (
          <Item key={index}>
            <ItemNumber>{number}</ItemNumber>
            <ItemText>
              <Icon name={icon} source="feather" /> {title}
            </ItemText>
          </Item>
        ))}
      </Items>
      <MediaLogos ref={logos}>
        {mediaLogosGroup(0)}
        {mediaLogosGroup(1)}
        {mediaLogosGroup(2)}
      </MediaLogos>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 150px;
  padding: 40px 0;
  padding-left: 50px;
  padding-right: 10px;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 30px 80px rgba(0, 11, 33, 0.08);

  ${media.tablet} {
    padding-left: 70px;
    padding-right: 30px;
  }

  ${media.desktop} {
    margin-bottom: 205px;
    padding: 60px 0;
    padding-left: 80px;
    padding-right: 40px;
  }
`

const Title = styled(SecondaryTitle)`
  display: none;
  margin-bottom: 0;

  ${media.desktop} {
    display: block;
  }
`

const Items = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 560px;
  max-width: 100%;
`

const Item = styled.li`
  width: 270px;
`

const ItemNumber = styled.h4`
  margin-bottom: 8px;
  font-size: 25px;
  line-height: 1;
  font-family: ${(p) => p.theme.font.secondary};
  color: #000;

  ${media.tablet} {
    font-size: 38px;
  }
`

const ItemText = styled.p`
  max-width: 100px;
  font-size: 13px;
  color: ${(p) => p.theme.color.textLight};

  ${media.tablet} {
    max-width: none;
    font-size: 15px;
  }
`

const Icon = styled(BaseIcon)`
  opacity: 0.5;
  width: 18px;
  height: 18px;
  margin-right: 7px;

  li:first-child & {
    width: 15px;
    height: 15px;
    margin-top: -2px;
    margin-right: 8px;
  }
`

const marquee = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`

const marquee2 = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
`

const animationDuration = 60

const MediaLogos = styled.div`
  width: calc(100% + 40px);
  overflow: hidden;
  margin: 40px 0 0 -40px;
  position: relative;

  &::before,
  &::after {
    content: "";
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    pointer-events: none;
  }

  &::after {
    left: auto;
    right: 0;
    transform: rotate(180deg);
  }

  &:hover div {
    animation-play-state: paused;
  }

  ${media.tablet} {
    &::before,
    &::after {
      width: 300px;
    }
  }

  ${media.desktop} {
    margin-top: 50px;
  }
`

const MediaLogosGroup = styled.div`
  display: flex;
  align-items: center;
  width: 1519px;

  &:nth-child(2) {
    height: 100%;
    position: absolute;
    top: 0;
    left: -1519px;
    animation-iteration-count: 1;
  }

  &:nth-child(3) {
    height: 100%;
    position: absolute;
    top: 0;
    left: 1519px;
    animation-name: ${marquee2};
    animation-delay: ${animationDuration / 2}s;
  }

  ${MediaLogos}.-animation-init & {
    animation: ${marquee} ${animationDuration}s linear infinite;
  }
`

const MediaLogo = styled.img`
  height: ${(p) => p.height || 14}px;
  margin: 10px 20px;
`

export default SectionAchievementsStats
