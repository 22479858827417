import React, { useState, useRef } from "react"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import { PopOutBig } from "src/styles/keyframes"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import { SectionTag, PrimaryTitle } from "src/components/Typography"

const pointsCount = 7

const SectionAbout = ({ label, title, text }) => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()
  const mapTitle = 'Anima International countries map'

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? "-observed" : ""}>
      <Illustration>
        <BackgroundImage
          src="/images/map.png"
          srcSet="/images/map@2x.png 2x"
          loading="lazy"
          alt={mapTitle}
        />
        <Points>
          {[...Array(pointsCount)].map((point, index) => (
            <Point key={index}>
              <PointIcon name={`avatar-${index + 1}`} />
            </Point>
          ))}
        </Points>
      </Illustration>
      <BaseContainer>
        <Text>
          <SectionTag>{label}</SectionTag>
          <Title>{title}</Title>
          <Paragraph dangerouslySetInnerHTML={{ __html: text }} />
        </Text>
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
  margin-bottom: 60px;
  padding-top: 230px;
  position: relative;

  ${media.tablet} {
    overflow: visible;
    margin-bottom: 80px;
    padding-top: 0;
  }

  ${media.desktop} {
    margin-bottom: 215px;
  }
`

const Illustration = styled.div`
  width: 100%;
  height: 1064px;
  position: absolute;
  top: -90px;
  transform: scale(0.65);
  transform-origin: 50% 0%;
  pointer-events: none;

  ${media.tablet} {
    width: 100%;
    overflow: hidden;
    transform: scale(0.9);
    transform-origin: 100% 50%;
  }

  ${media.desktop} {
    transform: none;
  }
`

const BackgroundImage = styled.img`
  opacity: 0;
  max-width: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(calc(-50% - 300px));
  transition: opacity 0.5s 0.25s ${(p) => p.theme.easing.easeInOutQuad};

  ${Wrapper}.-observed & {
    opacity: 1;
  }

  ${media.tablet} {
    transform: translateX(calc(-50% - 200px));
  }

  ${media.desktop} {
    transform: translateX(calc(-50% - 100px));
  }

  @media (min-width: 1366px) {
    transform: translateX(-50%);
  }
`

const Points = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 300px);

  ${media.tablet} {
    left: calc(50% - 200px);
  }

  ${media.desktop} {
    left: calc(50% - 100px);
  }

  @media (min-width: 1366px) {
    left: 50%;
  }
`

const Point = styled.div`
  width: 44px;
  height: 44px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
  background: #fff;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 0 16px 16px rgba(3, 34, 33, 0.1);

  ${Wrapper}.-observed & {
    animation-name: ${PopOutBig};
    animation-duration: 1.5s;
    animation-timing-function: ${(p) => p.theme.easing.easeOutQuart};
    animation-fill-mode: forwards;
  }

  // The United Kingdom
  &:nth-child(1) {
    top: 390px;
    left: 168px;
    animation-delay: ${pointTransitionDelay(0)};
  }

  // Norway
  &:nth-child(2) {
    top: 264px;
    left: 258px;
    animation-delay: ${pointTransitionDelay(1)};
  }

  // Denmark
  &:nth-child(3) {
    top: 348px;
    left: 285px;
    animation-delay: ${pointTransitionDelay(2)};
  }

  // Poland
  &:nth-child(4) {
    top: 417px;
    left: 367px;
    animation-delay: ${pointTransitionDelay(3)};
  }

  // Ukraine
  &:nth-child(5) {
    top: 464px;
    left: 477px;
    animation-delay: ${pointTransitionDelay(4)};
  }
  
  // France
  &:nth-child(6) {
    top: 485px;
    left: 200px;
    animation-delay: ${pointTransitionDelay(5)};
  }

  // Bulgaria
  &:nth-child(7) {
    top: 520px;
    left: 425px;
    animation-delay: ${pointTransitionDelay(5)};
  }
`

const PointIcon = styled(BaseIcon)`
  width: 100%;
  height: 26px;
  position: absolute;
  top: calc(50% + 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: #3e3d4e; */
  color: #0e0c43;
`

const Text = styled.div`
  position: relative;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    /* 'section$' is needed, otherwise prettier goes crazy */
    section${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  ${media.tablet} {
    max-width: 310px;
  }

  ${media.desktop} {
    max-width: 390px;
  }

  ${media.desktopMedium} {
    max-width: 430px;
  }
`

const Title = styled(PrimaryTitle)`
  transition-delay: 0.15s;
`

const Paragraph = styled.p`
  white-space: pre-line;
  transition-delay: 0.3s;
`

function pointTransitionDelay(index) {
  return `${0.8 + index * 0.075}s`
}

export default SectionAbout
