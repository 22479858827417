export default function parseContent(content) {
  const embeddedVideoRegex = /<(oembed).*[\n]?.*<\/(oembed)>/igm
  const separatorRegex = /<p\s?(([\w-]+)="([^"]*)"\s?)*>(\.{3}|\n)<\/p>/igm
  let embeddedVideos = []
  let separators = []
  
  if (content && content.length) {
    embeddedVideos = content.match(embeddedVideoRegex)
    separators = content.match(separatorRegex)

    // parse embedded youtube videos
    if (embeddedVideos && embeddedVideos.length) {
      embeddedVideos.forEach((video) => {
        const embeddedVideoComponent = parseEmbeddedVideo(video)
        content = content.replace(video, embeddedVideoComponent)
      })
    }

    // parse separators
    if (separators && separators.length) {
      separators.forEach((separator) => {
        const separatorComponent = '<hr />'
        content = content.replace(separator, separatorComponent)
      })
    }
  }

  return content
}

export function parseEmbeddedVideo(video) {
  // check if video element has url attribute
  let videoUrl = video.match(/(\burl=[ ]?")[^"]*/igm)

  if (videoUrl) {
    // extract url from the url attribute
    let url = videoUrl[0].replace(/^url=[ ]?"/g, '')
    // convert url to youtube embed url
    let youtubeUrl = `https://www.youtube.com/embed/${getYoutubeVideoId(url)}`
    return `<iframe src="${youtubeUrl}"></iframe>`
  } else {
    return video
  }
}

export function getYoutubeVideoId(url){
  // checks for different youtube url formats
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
  const match = url.match(regExp)
  // checks for the youtube video id which has a standard length of 11 characters
  if (match && match[1].length === 11) {
    return match[1]
  } else {
    return null
  }
}
