import React, { useState, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import { SectionTag, PrimaryTitle } from "src/components/Typography"
import { PopOut } from "src/styles/keyframes"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import Circle from "src/components/Circle"

const SectionTextWithCircle = ({
  tag,
  title,
  text,
  icon,
  image,
  imageSharp,
  textAlign,
}) => {
  const [observed, setObserved] = useState(false)
  const [textObserved, setTextObserved] = useState(false)
  const wrapper = useRef()
  const textWrapper = useRef()
  const srcSet = image?.url2x ? `${image.url2x} 2x` : null

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setTextObserved(true)
      }
    },
    elementRef: textWrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? "-observed" : ""}>
      <Container textAlign={textAlign}>
        <Text
          ref={textWrapper}
          textAlign={textAlign}
          className={textObserved ? "-observed" : ""}
        >
          {tag && <SectionTag>{tag}</SectionTag>}
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Paragraph dangerouslySetInnerHTML={{ __html: text }} />
        </Text>
        <Figure>
          <StyledCircle />
          {imageSharp ? (
            <ImageSharp image={imageSharp.gatsbyImageData} alt={title} />
          ) : (
            <Image src={image?.url} srcSet={srcSet} loading="lazy" alt={title} />
          )}
          {icon && (
            <Stamp>
              <Icon name={icon} className={`-${icon}`} />
            </Stamp>
          )}
        </Figure>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;

  position: relative;

  ${media.tablet} {
    margin-bottom: 30px;
  }

  ${media.desktop} {
    margin-bottom: 145px;
  }
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: ${(p) => (p.textAlign === "top" ? null : "center")};

  ${media.tablet} {
    flex-direction: row;

    /* 'section' is needed, otherwise prettier goes crazy */
    section${Wrapper}:nth-of-type(even) & {
      flex-direction: row-reverse;
    }
  }
`

const Text = styled.div`
  padding-bottom: ${(p) => (p.textAlign === "top" ? null : "20px")};
  text-align: center;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  > :nth-child(3) {
    transition-delay: 0.3s;
  }

  &:not(.-observed) {
    > * {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  ${media.tablet} {
    max-width: 390px;
    text-align: inherit;
  }

  ${media.desktopMedium} {
    max-width: 430px;
  }
`

const Title = styled(PrimaryTitle)`
  max-width: 10em;
  margin-left: auto;
  margin-right: auto;

  ${media.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Paragraph = styled.p`
  text-align: left;
`

const Figure = styled.figure`
  width: 491px;
  min-width: 491px;
  height: 491px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-right: -100px;
  margin-bottom: -70px;
  position: relative;
  transform: scale(0.6);
  transform-origin: 25% 50%;
  pointer-events: none;

  ${media.tablet} {
    margin-top: 0;
    margin-bottom: 0;
    transform: scale(0.7);
    transform-origin: 0 50%;

    /* 'section' is needed, otherwise prettier goes crazy */
    section${Wrapper}:nth-of-type(odd) & {
      margin-left: -100px;
      margin-right: 0;
      transform-origin: 100% 50%;
    }
  }

  ${media.desktop} {
    transform: scale(0.9);

    &,
    section${Wrapper}:nth-of-type(odd) & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 1250px) {
    transform: none;
  }
`

const StyledCircle = styled(Circle)`
  position: absolute;
  top: 0;
  left: 0;
`

const imageStyles = css`
  width: 409px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  transform: scale(0);

  ${Wrapper}.-observed & {
    animation: ${PopOut} 1.5s 0.65s ${(p) => p.theme.easing.easeOutQuart};
    animation-fill-mode: forwards;
  }
`

const Image = styled.img`
  ${imageStyles}
`

const ImageSharp = styled(GatsbyImage)`
  ${imageStyles}
`

const Stamp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 133px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 2;
  transform: scale(0);
  border-radius: 50%;
  background: linear-gradient(rgb(80, 217, 214), rgb(58, 182, 218));
  color: #fff;

  ${Wrapper}.-observed & {
    animation: ${PopOut} 1.5s 0.8s ${(p) => p.theme.easing.easeOutQuart};
    animation-fill-mode: forwards;
  }
`

const Icon = styled(BaseIcon)`
  width: 70px;
  height: 70px;
  position: relative;

  &.-exchange {
    width: 60px;
    height: 60px;
  }

  &.-team-flag {
    width: 72px;
    height: 72px;
    top: -5px;
    left: 3px;
  }
`

export default SectionTextWithCircle
