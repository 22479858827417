import React, { useState, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseButton from "src/components/Base/BaseButton"
import { PrimaryTitle } from "src/components/Typography"
import { get } from "lodash"

const SectionMoreFunding = ({ title, subtitle, areas, linkText, linkPath }) => {
  const [observed, setObserved] = useState(false)
  const [columnsObserved, setColumnsObserved] = useState(false)
  const wrapper = useRef()
  const columns = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setColumnsObserved(true)
      }
    },
    elementRef: columns,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? "-observed" : ""}>
      <Container>
        <PrimaryTitle>{title}</PrimaryTitle>
        <Subtitle>{subtitle}</Subtitle>
        <Columns ref={columns} className={columnsObserved ? "-observed" : ""}>
          {areas.map(({ text, image, link }, index) => {
            return (
              <Column key={index}>
                <ImageWrapper to={link} title={title}>
                  <Image
                    image={get(
                      image,
                      "localFile.childImageSharp.gatsbyImageData"
                    )}
                    alt={title}
                  />
                </ImageWrapper>
                <Paragraph dangerouslySetInnerHTML={{ __html: text }} />
              </Column>
            )
          })}
        </Columns>
        <BaseButton label={linkText} size="medium" to={linkPath} />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: -205px;
  margin-bottom: 170px;
  text-align: center;

  ${media.desktop} {
    margin-bottom: 205px;
  }
`

const Container = styled(BaseContainer)`
  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    ${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }
`

const Subtitle = styled.p`
  margin-top: -30px;
  margin-bottom: 80px;

  ${media.tablet} {
    /* margin-top: 0; */
    margin-bottom: 100px;
  }
`

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  > :nth-child(3) {
    transition-delay: 0.3s;
  }

  &:not(.-observed) > * {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 70px;
  }
`

const Column = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
  padding-bottom: 30px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 60px 80px rgba(0, 11, 33, 0.08);

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
  }
  ${media.tablet} {
    width: calc((100% - 20px * 2) / 3);
    margin-bottom: 0;
  }

  ${media.desktop} {
    width: calc((100% - 35px * 2) / 3);
    padding-bottom: 50px;
  }
`

const ImageWrapper = styled(Link)`
  display: block;
  overflow: hidden;
  margin-bottom: 30px;

  ${media.desktop} {
    margin-bottom: 50px;
  }
`
const Image = styled(GatsbyImage)`
  width: 100%;
  position: relative;
  float: left;
`

const Paragraph = styled.p`
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
  font-size: 13px;
  color: ${(p) => p.theme.color.textLight};

  strong,
  b {
    color: #000;
  }

  ${media.desktop} {
    margin-left: 35px;
    margin-right: 35px;
    font-size: 15px;
  }
`

export default SectionMoreFunding
