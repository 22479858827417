import React, { useState, useRef } from "react"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"

const Circle = ({ size = 491, className }) => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()
  const radius = size / 2

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      } else {
        // setObserved(false)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper
      ref={wrapper}
      size={size}
      className={className + (observed ? " -observed" : "")}
    >
      <Svg>
        <Shape cx={radius} cy={radius} r={radius} />
      </Svg>
      <CircleOverlay>
        <CircleOverlayShape />
      </CircleOverlay>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  position: relative;
  background: linear-gradient(
    20deg,
    #81e2cc 7%,
    #f3bbd9 35%,
    #3ab6da 49%,
    #3ab6da 51%,
    #f3bbd9 65%,
    #81e2cc 93%
  );
  border-radius: 50%;

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: #fff;
    border-radius: 50%;
  }
`

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
`

const Shape = styled.circle`
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  stroke-dasharray: 4 8;
`

const CircleOverlay = styled.svg`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: rotateX(180deg) rotate(90deg);
  fill: none;

  ${media.tablet} {
    display: block;
  }
`

const CircleOverlayShape = styled.circle`
  r: 123.3;
  cx: 245.5;
  cy: 245.5;
  stroke: #fff;
  stroke-width: 245.5;
  stroke-dasharray: 774.712 774.712; /* 2π × r ≈ 774.712 */
  transition: stroke-dasharray 1s ${(p) => p.theme.easing.easeInOutQuart};
  /* transition: stroke-dasharray 1.6s ${(p) =>
    p.theme.easing.easeInOutQuad}; */

  ${Wrapper}.-observed & {
    stroke-dasharray: 0 774.712;
  }
`

export default Circle
