import React, { useState } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import { PrimaryTitle } from "src/components/Typography"

const SectionSupporters = ({ title, supporters }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Wrapper>
      <BaseContainer>
        <Title>{title}</Title>
        <Quotes activeIndex={activeIndex}>
          {supporters.map(({ quote }, index) => (
            <Quote
              className={index === activeIndex ? "-active" : ""}
              key={index}
            >
              {quote}
            </Quote>
          ))}
        </Quotes>
        <Items>
          {supporters.map(({ name, description, image }, index) => (
            <Item
              className={index === activeIndex ? "-active" : ""}
              key={index}
              onMouseEnter={() => {
                setActiveIndex(index)
              }}
            >
              <ItemLogoWrap>
                <ItemLogo src={image.url} loading="lazy" alt={name + ' logo'}/>
              </ItemLogoWrap>
              <ItemName>{name}</ItemName>
              <ItemTitle>{description}</ItemTitle>
            </Item>
          ))}
        </Items>
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 150px;
  position: relative;
  text-align: center;

  &::before {
    content: "";
    width: 100%;
    height: 705px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(#fff, #f4f6f7);
  }

  @media (min-width: 330px) {
    &::before {
      height: 595px;
    }
  }

  ${media.tablet} {
    margin-bottom: 215px;
  }
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 70px;
  position: relative;

  ${media.tablet} {
    margin-bottom: 95px;
  }
`

const Quotes = styled.div`
  width: 780px;
  max-width: 100%;
  height: 460px;
  margin: 0 auto 10px;
  position: relative;
  background-image: linear-gradient(#50d9d6, #3ab6da);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);
  text-align: left;
  color: #fff;
  transform: translateX(${(p) => (p.activeIndex - 1) * 10}px);
  transition: transform 0.7s ${(p) => p.theme.easing.easeOutExpo};

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    margin-left: ${(p) => -110 + p.activeIndex * 110}px;
    position: absolute;
    bottom: -7px;
    left: calc(50% - 7px);
    transform: rotate(45deg);
    background: #3ab6da;
  }

  @media (min-width: 330px) {
    height: 390px;
  }

  ${media.tablet} {
    height: 355px;

    &::before {
      margin-left: ${(p) => -300 + p.activeIndex * 300}px;
    }
  }
`

const Quote = styled.article`
  display: flex;
  align-items: center;
  visibility: hidden;
  max-width: 590px;
  margin: auto;
  padding-left: 60px;
  padding-right: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 13px;
  line-height: 1.6;

  &::before {
    content: "”";
    opacity: 0.2;
    position: absolute;
    top: 40px;
    left: 20px;
    font-size: 190px;
    line-height: 1;
    font-family: "Arial";
  }

  &.-active {
    visibility: visible;
  }

  ${media.tablet} {
    padding: 0;
    font-size: inherit;
    line-height: inherit;

    &::before {
      left: -30px;
    }
  }
`

const Items = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

const Item = styled.div`
  width: 300px;
  padding-top: 50px;
`

const ItemLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin: 0 auto 32px;
  position: relative;
  transform: scale(0.93);
  background: #fff;
  border-radius: 50%;
  transition: 0.25s;

  ${Item}.-active & {
    transform: none;
    box-shadow: 0 20px 50px rgba(0, 11, 33, 0.1);
  }
`

const ItemLogo = styled.img`
  opacity: 0.5;
  width: 56px;
  border-radius: 11px;
  transition: opacity 0.25s;

  ${Item}.-active & {
    opacity: 1;
  }
`

const ItemName = styled.h3`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.53;
  color: #000;

  ${media.tablet} {
    font-size: inherit;
  }
`

const ItemTitle = styled.h4`
  font-size: 13px;
  color: ${(p) => p.theme.color.textLight};

  ${media.tablet} {
    font-size: 15px;
  }
`

export default SectionSupporters
