import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import { get } from "lodash"
import getSlug from "src/parsers/slug-parser"

const BlogPostList = ({ posts }) => (
  <Wrapper>
    {posts.map(({ title, date, slug, cover_image }, index) => (
      <Post key={index}>
        <PostLink to={`/blog/${getSlug({ slug, title })}`}>
          {cover_image && (
            <PostImageWrapper>
              {get(cover_image, "localFile.childImageSharp.gatsbyImageData") ? (
                <PostImageSharp
                  image={get(
                    cover_image,
                    "localFile.childImageSharp.gatsbyImageData"
                  )}
                  alt=""
                />
              ) : (
                <PostImage
                  src={cover_image.localFile.publicURL}
                  loading="lazy"
                />
              )}
            </PostImageWrapper>
          )}
          <PostTitle>{title}</PostTitle>
          <PostDate>{date}</PostDate>
        </PostLink>
      </Post>
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }

  ${media.desktopMedium} {
    margin-left: -26px;
    margin-right: -26px;
  }
`

const Post = styled.article`
  margin-bottom: 20px;
  position: relative;

  &::before {
    content: "";
    opacity: 0;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    background: linear-gradient(-20deg, #3ab6da, #50d9d6);
    border-radius: 3px;
    box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);
    pointer-events: none;
    transition: opacity 0.15s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    z-index: 1;
    background: #fff;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  ${media.tablet} {
    width: calc((100% - 20px * 2) / 3);
  }

  ${media.desktop} {
    width: calc((100% - 25px * 2) / 3);
  }

  ${media.desktopMedium} {
    width: calc((100% - 35px * 2) / 3);
  }
`

const PostLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  border: 1px solid #cfdbdb;
  border-radius: 3px;

  ${media.desktop} {
    padding: 25px;
  }
`

const PostImageWrapper = styled.div`
  height: calc((100vw - 82px) / 1.5);
  margin-bottom: 25px;
  position: relative;
  z-index: 2;

  ${media.tablet} {
    height: 118px;
  }

  ${media.desktop} {
    height: 160px;
    margin-bottom: 38px;
  }

  ${media.desktopMedium} {
    height: 192px;
  }
`

const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
`

const PostImageSharp = styled(GatsbyImage)`
  width: 100% !important;
  height: 100% !important;
  border-radius: 3px;
`

const PostTitle = styled.h3`
  flex: 1;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.6;
  color: #000;

  ${media.desktop} {
    margin-bottom: 27px;
    font-size: 20px;
  }
`

const PostDate = styled.p`
  position: relative;
  z-index: 2;
  font-size: 14px;
  color: ${(p) => p.theme.color.textLight};
`

export default BlogPostList
