import React, { useState, useRef } from "react"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseButton from "src/components/Base/BaseButton"
import { PrimaryTitle } from "src/components/Typography"
import SectionResourcesIllustration from "./SectionResourcesIllustration"

const SectionResources = ({ title, text }) => {
  const [observed, setObserved] = useState(false)
  const textWrapper = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      } else {
        // setObserved(false)
      }
    },
    elementRef: textWrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper>
      <Container>
        <Text ref={textWrapper} className={observed ? "-observed" : ""}>
          <Title>{title}</Title>
          {text.map(({ text }, index) => (
            <Paragraph key={index}>{text}</Paragraph>
          ))}
          <BaseButton label="Explore" size="medium" to="/resources" />
        </Text>
        <SectionResourcesIllustration />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
  margin-bottom: 150px;
  position: relative;

  ${media.tablet} {
    overflow: visible;
  }

  ${media.desktop} {
    margin-bottom: 225px;
  }
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column-reverse;

  ${media.tablet} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

const Text = styled.div`
  position: relative;
  text-align: center;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  > :nth-child(3) {
    transition-delay: 0.3s;
  }

  &:not(.-observed) > * {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    max-width: 310px;
    text-align: inherit;
  }

  ${media.desktop} {
    max-width: 390px;
  }

  ${media.desktopMedium} {
    max-width: 430px;
  }
`

const Title = styled(PrimaryTitle)``

const Paragraph = styled.p`
  margin-bottom: 1em;
  text-align: left;

  &:last-of-type {
    margin-bottom: 60px;
  }
`

export default SectionResources
