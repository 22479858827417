import React, { useRef, useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import { SecondaryTitle } from "src/components/Typography"
import { PopOut } from "src/styles/keyframes"
import BaseIcon from "src/components/Base/BaseIcon"
import BaseFlagIcon from "src/components/Base/BaseFlagIcon"
import Circle from "src/components/Circle"
import { slugify } from "src/utils/helpers"
import { get } from "lodash"

/* eslint-disable import/no-anonymous-default-export */
export default ({ achievements }) => {
  const wrapper = useRef()
  const groupRefs = []
  const figureRefs = []
  const lineRefs = []

  function textInnerHelper() {
    const inners = wrapper.current.querySelectorAll(".text-inner")
    inners.forEach((inner) => {
      const height = inner.offsetHeight
      const marginTop = (90 - height) / 2
      inner.style.marginTop = marginTop + "px"
    })
  }

  useEffect(() => {
    textInnerHelper()
  })

  return (
    <Wrapper ref={wrapper}>
      {achievements.map(({ title, subtitle, icon, image, list }, index) => {
        const [observed, setObserved] = useState(false)
        const [figureObserved, setFigureObserved] = useState(false)
        const [lineObserved, setLineObserved] = useState(false)
        const listLayout =
          title === "Plant-based revolution" ? "custom-1" : list.length
        const groupRef = {
          current: (groupRef) => (groupRefs[index] = groupRef),
        }
        const figureRef = {
          current: (figureRef) => (figureRefs[index] = figureRef),
        }
        const lineRef = {
          current: (lineRef) => (lineRefs[index] = lineRef),
        }
        const iconSource = icon === "leaf" ? "base" : null
        if (icon === "check") {
          icon = "check-circle"
        }

        useIntersectionObserver({
          callback: (change) => {
            if (change.isIntersecting) {
              setObserved(true)
            }
          },
          elementRef: groupRef,
          options: {
            rootMargin: "-100px",
          },
        })

        useIntersectionObserver({
          callback: (change) => {
            if (change.isIntersecting) {
              setFigureObserved(true)
            }
          },
          elementRef: figureRef,
          options: {
            rootMargin: "-100px",
          },
        })

        useIntersectionObserver({
          callback: (change) => {
            if (change.isIntersecting) {
              setLineObserved(true)
            }
          },
          elementRef: lineRef,
          options: {
            rootMargin: "-100px",
          },
        })

        return (
          <Group
            ref={groupRef}
            className={observed ? "-observed" : ""}
            id={`achievements-${index + 1}`}
            key={index}
          >
            <Number>{index + 1}</Number>
            {iconSource === "base" ? (
              <Icon name={icon} />
            ) : (
              <Icon name={icon} source="feather" />
            )}
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Figure
              ref={figureRef}
              className={figureObserved ? "-observed" : ""}
            >
              <StyledCircle />
              <Image
                image={get(image, "localFile.childImageSharp.gatsbyImageData")}
                alt=""
              />
              <Nodes className={`-layout-${listLayout}`}>
                {list.map(({ title, subtitle, country, image }, listIndex) => (
                  <Node key={listIndex}>
                    <NodeLine>
                      <LineShape x1="0" x2="900" />
                    </NodeLine>
                    <Item>
                      <ItemInner>
                        <ItemCircle>
                          <ItemImage
                            image={get(
                              image,
                              "localFile.childImageSharp.gatsbyImageData"
                            )}
                            alt=""
                          />
                          {country && (
                            <ItemCircleMark>
                              <ItemCircleFlag
                                country={slugify(country?.name)}
                              />
                            </ItemCircleMark>
                          )}
                        </ItemCircle>
                        <ItemText>
                          <ItemTextPath />
                          <ItemTextInner className="text-inner">
                            <span dangerouslySetInnerHTML={{ __html: title }} />
                            {subtitle && (
                              <small
                                dangerouslySetInnerHTML={{ __html: subtitle }}
                              />
                            )}
                          </ItemTextInner>
                        </ItemText>
                      </ItemInner>
                    </Item>
                  </Node>
                ))}
              </Nodes>
              <GroupLine
                ref={lineRef}
                className={lineObserved ? "-observed" : ""}
              >
                <LineShape x1="0" x2="900" />
              </GroupLine>
            </Figure>
          </Group>
        )
      })}
    </Wrapper>
  )
}

const itemDistance = 300
const lineAngle = 35
const lineAngleFor2 = 20
const textWidth = 240

const Wrapper = styled.div`
  margin-top: 120px;

  ${media.desktop} {
    margin-top: 180px;
  }
`

const Group = styled.article`
  margin-bottom: 330px;
  position: relative;

  ${media.tablet} {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: -45px;
    }
  }

  ${media.desktop} {
    margin-bottom: 175px;

    &:last-child {
      margin-bottom: 90px;
    }
  }

  @media (min-width: 1250px) {
    margin-bottom: 310px;

    &:last-child {
      margin-bottom: 213px;
    }
  }

  @media (max-width: 767px) {
    &:nth-child(2) {
      margin-bottom: 380px;
    }

    &:nth-child(3) {
      margin-bottom: 305px;
    }

    &:nth-child(4) {
      margin-bottom: 410px;
    }

    &:nth-child(5) {
      margin-bottom: 560px;
    }
  }
`

const Number = styled.span`
  width: 200px;
  position: absolute;
  top: -55px;
  left: calc(50% - 100px);
  font-size: 200px;
  line-height: 1;
  color: rgba(12, 104, 116, 0.05);
  pointer-events: none;
  /* transition: 0.7s ${(p) => p.theme.easing.easeOutQuart}; */
`

const Icon = styled(BaseIcon)`
  width: 24px;
  height: 24px;
  margin-bottom: 22px;
  color: #46cad8;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  ${Group}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }
`

const Title = styled(SecondaryTitle)`
  max-width: 15em;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

  ${Group}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }
`

const Subtitle = styled.p`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
  transition: 0.6s 0.3s ${(p) => p.theme.easing.easeOutQuart};

  ${Group}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 80px;
  }
`

const Figure = styled.div`
  width: 491px;
  height: 491px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform: scale(0.6);
  transform-origin: -4% 0;

  @media (min-width: 340px) {
    transform-origin: 6% 0;
  }

  @media (min-width: 370px) {
    transform-origin: 10% 0;
  }

  @media (min-width: 400px) {
    transform-origin: 19% 0;
  }

  @media (min-width: 530px) {
    transform: scale(0.63);
    transform-origin: 50% 0;
  }

  ${media.desktop} {
    transform: scale(0.82);
  }

  @media (min-width: 1250px) {
    transform: none;
  }
`

const StyledCircle = styled(Circle)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  ${media.tablet} {
    display: block;
  }
`

const Image = styled(GatsbyImage)`
  width: 409px;
  position: relative;
  z-index: 2;
  transform: scale(0);
  border-radius: 50%;
  pointer-events: none;

  ${Figure}.-observed & {
    animation: ${PopOut} 1.5s 0.65s ${(p) => p.theme.easing.easeOutQuart};
    animation-fill-mode: forwards;
  }
`

const Nodes = styled.div`
  position: absolute;
  top: calc(100% + 60px);
  left: 60px;

  ${media.tablet} {
    top: 50%;
    left: 50%;
  }
`

const ItemInner = styled.div`
  display: flex;
  /* align-items: center; */
  transform-origin: 45px 50%;
  text-align: left;
`

const ItemCircle = styled.div`
  width: 90px;
  min-width: 90px;
  height: 90px;
  position: relative;
  transform: scale(0);
  /* background: #f5f5f5; */
  border-radius: 50%;
  /* box-shadow: 0 30px 60px rgba(0, 11, 33, 0.15); */
  /* transition: transform 0.25s; */

  ${Figure}:not(.-observed) & {
    /* transform: scale(0); */
  }

  ${Figure}.-observed & {
    animation: ${PopOut} 1.5s 0.8s ${(p) => p.theme.easing.easeOutQuart};
    animation-fill-mode: forwards;
  }
`

const ItemText = styled.div`
  width: ${textWidth}px;
  margin-left: 30px;
  transition: 0.6s 1s ${(p) => p.theme.easing.easeOutQuart};

  ${Figure}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-left: 0;
    position: relative;
    left: -15px;
  }
`

const ItemTextPath = styled.div`
  ${media.tablet} {
    float: left;
    width: 90px;
    height: 90px;
    shape-outside: circle(50% at 0 50%);
    clip-path: circle(50% at 0 50%);
  }
`

const Node = styled.div`
  ${media.tablet} {
    width: 600px;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 50%;

    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5) {
      ${ItemInner} {
        text-align: right;
      }

      ${ItemText} {
        left: -${textWidth + 75}px;

        small {
          left: auto;
          right: 27px;
        }
      }

      ${ItemTextPath} {
        float: right;
        shape-outside: circle(50% at 100% 50%);
        clip-path: circle(50% at 100% 50%);
      }
    }

    &:nth-child(2) {
      ${ItemCircle} {
        animation-delay: 0.9s;
      }

      ${ItemText} {
        transition-delay: 1.1s;
      }
    }

    &:nth-child(3) {
      ${ItemCircle} {
        animation-delay: 1s;
      }

      ${ItemText} {
        transition-delay: 1.2s;
      }
    }

    &:nth-child(4) {
      ${ItemCircle} {
        animation-delay: 1.1s;
      }

      ${ItemText} {
        transition-delay: 1.3s;
      }
    }

    &:nth-child(5) {
      ${ItemCircle} {
        animation-delay: 1.2s;
      }

      ${ItemText} {
        transition-delay: 1.4s;
      }
    }

    &:nth-child(6) {
      ${ItemCircle} {
        animation-delay: 1.3s;
      }

      ${ItemText} {
        transition-delay: 1.5s;
      }
    }

    ${Nodes}.-layout-5 &,
    ${Nodes}.-layout-6 &,
    ${Nodes}.-layout-custom-1 & {
      &:nth-child(1) {
        transform: rotate(${-180 + lineAngle}deg);

        ${ItemInner} {
          transform: rotate(${180 - lineAngle}deg);
        }
      }

      &:nth-child(3) {
        transform: rotate(-180deg);

        ${ItemInner} {
          transform: rotate(180deg);
        }
      }

      &:nth-child(5) {
        transform: rotate(${-180 - lineAngle}deg);

        ${ItemInner} {
          transform: rotate(${180 + lineAngle}deg);
        }
      }
    }

    ${Nodes}.-layout-5 & {
      &:nth-child(2) {
        transform: rotate(-${lineAngleFor2}deg);

        ${ItemInner} {
          transform: rotate(${lineAngleFor2}deg);
        }
      }

      &:nth-child(4) {
        transform: rotate(${lineAngleFor2}deg);

        ${ItemInner} {
          transform: rotate(-${lineAngleFor2}deg);
        }
      }
    }

    ${Nodes}.-layout-6 & {
      &:nth-child(2) {
        transform: rotate(-${lineAngle}deg);

        ${ItemInner} {
          transform: rotate(${lineAngle}deg);
        }
      }

      &:nth-child(4) {
        /* transform: rotate(0deg); */
      }

      &:nth-child(6) {
        transform: rotate(${lineAngle}deg);

        ${ItemInner} {
          transform: rotate(-${lineAngle}deg);
        }
      }
    }

    ${Nodes}.-layout-custom-1 & {
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        ${ItemCircle} {
          width: 60px;
          min-width: 60px;
          height: 60px;
        }

        > svg {
          // opacity: 0;
        }
      }

      &:nth-child(2) {
        transform: rotate(-37deg);

        ${ItemInner} {
          transform: rotate(37deg);
        }

        > div {
          left: 370px;

          ${media.pointer} {
            &:hover {
              left: 377px;
            }
          }
        }
      }

      &:nth-child(4) {
        z-index: -1;
        transform: rotate(-16deg);

        ${ItemInner} {
          transform: rotate(16deg);
        }

        ${ItemCircle} {
          animation-delay: 1.2s;
        }

        ${ItemText} {
          transition-delay: 1.4s;
        }
      }

      &:nth-child(5) {
        ${ItemCircle} {
          animation-delay: 1.1s;
        }

        ${ItemText} {
          transition-delay: 1.3s;
        }
      }

      &:nth-child(6) {
        transform: rotate(4.5deg);

        ${ItemInner} {
          transform: rotate(-4.5deg);
        }

        ${ItemCircle} {
          animation-delay: 1.3s;
        }

        ${ItemText} {
          transition-delay: 1.5s;
        }
      }

      &:nth-child(7) {
        transform: rotate(27deg);

        ${ItemInner} {
          transform: rotate(-27deg);
        }

        ${ItemCircle} {
          animation-delay: 1.4s;
        }

        ${ItemText} {
          transition-delay: 1.6s;
        }
      }

      &:nth-child(8) {
        transform: rotate(53.5deg);

        ${ItemInner} {
          transform: rotate(-53.5deg);
        }

        ${ItemCircle} {
          animation-delay: 1.5s;
        }

        ${ItemText} {
          transition-delay: 1.7s;
        }
      }
    }
  }
`

const Line = styled.svg`
  max-width: none;
  position: absolute;
`

const NodeLine = styled(Line)`
  display: none;
  width: 380px;
  height: 100%;
  top: 0;
  left: 0;
  transition: width 1s linear;

  ${Figure}:not(.-observed) & {
    width: 0;
  }

  ${media.tablet} {
    display: block;
  }
`

const GroupLine = styled(Line)`
  display: none;
  width: 215px;
  height: 2px;
  top: 100%;
  left: 50%;
  transform: rotate(90deg);
  transform-origin: 0 50%;
  transition: width 1s ${(p) => p.theme.easing.easeInOutQuart};

  &:not(.-observed) {
    width: 0;
  }

  ${media.tablet} {
    display: block;
  }
`

const LineShape = styled.line`
  stroke: #81e2cc;
  stroke-width: 4;
  stroke-dasharray: 8 4;
`

const Item = styled.div`
  margin-bottom: 30px;

  ${media.pointer} {
    &:hover {
      left: ${itemDistance + 7}px;
    }
  }

  ${media.tablet} {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: ${itemDistance}px;
    transform: translateY(-50%);
    background: #fff;
    transition: left 0.25s;
    /* outline: 1px solid red; */
  }
`

const ItemImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;

  &,
  img {
    border-radius: 50%;
  }
`

const ItemCircleMark = styled.div`
  display: flex;
  /* display: none; */
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  position: absolute;
  right: -10px;
  bottom: -15px;
  background: #fff;
  box-shadow: 0 16px 16px rgba(0, 11, 33, 0.08);
  border-radius: 50%;
`

const ItemCircleFlag = styled(BaseFlagIcon)`
  width: 28px;
`

const ItemTextInner = styled.p`
  position: relative;
  font-size: 15px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.textLight};

  strong,
  b {
    font-weight: 500;
    color: #000;
  }

  small {
    /* display: block; */
  }

  ${media.tablet} {
    small {
      position: absolute;
      top: 100%;
      left: 40px;
      font-size: 12px;
    }
  }
`
