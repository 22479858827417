import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import { SectionTag, PrimaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import SectionAchievementsStats from "./SectionAchievementsStats"
import SectionAchievementsList from "./SectionAchievementsList"

const SectionAchievements = ({ label, title, achievements }) => {
  const { allStrapiAchievementsHomePage, allStrapiPage } = useStaticQuery(
    graphql`
      {
        allStrapiAchievementsHomePage {
          nodes {
            title
            subtitle
            country {
              name
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 90
                    height: 90
                    quality: 90
                    placeholder: NONE
                    layout: FIXED
                  )
                }
              }
            }
            achievement_home_page_section {
              id
            }
          }
        }
        allStrapiPage(filter: { Title: { eq: "Homepage" } }) {
          nodes {
            page_feature
          }
        }
      }
    `
  )

  const achievementsWithList = achievements.map(
    ({ id, title, subtitle, country, icon, cover_image }) => {
      return {
        title,
        subtitle,
        country,
        icon,
        image: cover_image,
        list: allStrapiAchievementsHomePage.nodes.filter(
          (item) =>
            item.achievement_home_page_section &&
            item.achievement_home_page_section.id === id
        ),
      }
    }
  )

  const stats = allStrapiPage.nodes[0].page_feature.find(
    (item) => item.social_media_followers
  )

  return (
    <Wrapper>
      <BaseContainer>
        <SectionTag>{label}</SectionTag>
        <Title>{title}</Title>
        <SectionAchievementsList achievements={achievementsWithList} />
        <SectionAchievementsStats
          socialMedia={stats.social_media_followers}
          traditionalMedia={stats.traditional_media_reach}
        />
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
  margin-bottom: 215px;
  text-align: center;

  ${media.desktop} {
    margin-bottom: 205px;
  }
`

const Title = styled(PrimaryTitle)`
  max-width: 10em;
  margin-left: auto;
  margin-right: auto;
`

export default SectionAchievements
