import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import { PrimaryTitle } from "src/components/Typography"
import NewsletterForm from "src/components/Form/NewsletterForm"

const SectionNewsletter = ({ title, text, image, buttonText }) => {
  return (
    <Wrapper>
      <Container>
        <Text>
          <Title>{title}</Title>
          <Paragraph>{text}</Paragraph>
          <NewsletterForm prominent buttonText={buttonText} />
        </Text>
        <Figure>
          <Image src={image.url} loading="lazy" alt={title} />
        </Figure>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  overflow: hidden;
  margin-bottom: 160px;

  ${media.desktop} {
    margin-bottom: 195px;
  }
`

const Container = styled(BaseContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.div`
  width: 350px;

  ${media.desktop} {
    width: 480px;
  }
`

const Title = styled(PrimaryTitle)`
  text-align: center;

  ${media.tablet} {
    text-align: inherit;
  }
`

const Paragraph = styled.p`
  max-width: 420px;
  margin-bottom: 70px;
`

const Figure = styled.figure`
  display: none;
  flex: 1;
  height: 600px;
  margin-left: 70px;
  position: relative;

  ${media.tablet} {
    display: block;
  }

  ${media.desktop} {
    margin-left: 100px;
  }
`

const Image = styled.img`
  max-width: none;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
`

export default SectionNewsletter
