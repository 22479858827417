import React, { useState, useRef } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import BaseContainer from "src/components/Base/BaseContainer"
import { PrimaryTitle } from "src/components/Typography"

const TextColumns = ({ title, leftParagraph, rightParagraph }) => {
  const [textObserved, setTextObserved] = useState(false)
  const wrapper = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setTextObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={textObserved ? "-observed" : ""}>
      <Title as="h1" dangerouslySetInnerHTML={{ __html: title }} />
      <BaseContainer>
        <Columns>
          <Column>
            <Paragraph dangerouslySetInnerHTML={{ __html: leftParagraph }} />
          </Column>
          <Column>
            <Paragraph dangerouslySetInnerHTML={{ __html: rightParagraph }} />
          </Column>
        </Columns>
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 30px;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  &:not(.-observed) > * {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 140px;
  }

  ${media.desktop} {
    margin-bottom: 180px;
  }
`

const Title = styled(PrimaryTitle)`
  text-align: center;

  ${media.tablet} {
    margin-bottom: 110px;
  }
`

const Columns = styled.div`
  ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`

const Column = styled.div`
  ${media.tablet} {
    width: calc(50% - 40px);
  }
`

const Paragraph = styled.p`
  p + & {
    margin-top: 1em;
  }
`

export default TextColumns
