import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseContent from "src/components/Base/BaseContent"
import BaseButton from "src/components/Base/BaseButton"
import TextColumns from "src/components/TextColumns"
import Hero from "src/components/Hero"
import SectionFullBleed from "src/components/Section/SectionFullBleed"
import SectionTextWithCircle from "src/components/Section/SectionTextWithCircle"
import SectionValues from "src/components/Section/SectionValues"
import ProgramList from "src/components/ProgramList"
import SectionStatement from "src/components/Section/SectionStatement"
import SectionLink from "src/components/Section/SectionLink"
import SectionAbout from "src/components/Section/SectionAbout"
import SectionAchievements from "src/components/Section/SectionAchievements/SectionAchievements"
import SectionMoreFunding from "src/components/Section/SectionMoreFunding"
import SectionResources from "src/components/Section/SectionResources/SectionResources"
import SectionBlog from "src/components/Section/SectionBlog"
import SectionNewsletter from "src/components/Section/SectionNewsletter"
import SectionSupporters from "src/components/Section/SectionSupporters"
import { PrimaryTitle } from "src/components/Typography"
import { get } from "lodash"
import parseContent from "../utils/parseContent"

const Page = (props) => {
  const {
    Title: title,
    header_image: headerImage,
    header_paragraph: headerParagraph,
    meta,
    page_feature: pageFeature,
    page_feature_images_323: pageFeatureImages323,
    page_feature_images_409: pageFeatureImages409,
    page_feature_images_fluid: pageFeatureImagesFluid,
  } = props.pageContext

  const [observed, setObserved] = useState(false)
  
  const metaTitle = meta.find((item) => item.name === "title")?.value
  const metaDescription = meta.find(
    (item) => item.name === "description"
  )?.value
  const metaImage = headerImage?.publicURL

  const components = pageFeature.map(
    (
      {
        label,
        header,
        headline,
        text,
        image,
        background,
        icon,
        left_paragraph,
        right_paragraph,
        features,
        title,
        learn_more_info,
        number,
        grey_text,
        link_text,
        link_address,
        description,
        social_media_followers,
        traditional_media_reach,
        column,
        call_to_action_button_text,
        call_to_action_button_link_address,
        call_to_action_link,
        achievement_home_page_sections,
        paragraph,
        supporter,
        url,
        button_text,
        page_paragraph_text,
        page_title_text,
        list_item_text,
        bold_page_paragraph_text,
        bold_list_item_text,
        content
      },
      index
    ) => {
      if (list_item_text && bold_list_item_text) {
        return (
          <ListContainer>
            <List>
              <ListItem><Bold>{bold_list_item_text}</Bold> {list_item_text}</ListItem>
            </List>
          </ListContainer>
        )
      }
      else if (list_item_text) {
        return (
          <ListContainer>
            <List>
              <ListItem>{list_item_text}</ListItem>
            </List>
          </ListContainer>
        )
      } else if (page_paragraph_text) {
        return (
          <ParagraphContainer>
            <Paragraph>{page_paragraph_text}</Paragraph>
          </ParagraphContainer>
        )
      } else if (bold_page_paragraph_text) {
        return (
          <ParagraphContainer>
            <Paragraph><Bold>{bold_page_paragraph_text}</Bold></Paragraph>
          </ParagraphContainer>
        )
      } else if (page_title_text) {
        return (
          <TitleContainer>
            <TitleNoSpacingBelow>{page_title_text}</TitleNoSpacingBelow>
          </TitleContainer>
        )
      } else if (url && button_text) {
        return (
          <ButtonContainer>
            <BaseButton label={button_text} size="medium" to={url} />
          </ButtonContainer>
        )
      } else if (text && grey_text && link_text && link_address) {
        return (
          <SectionStatement
            text={text}
            greyText={grey_text}
            linkText={link_text}
            linkPath={link_address}
            key={index}
          />
        )
      } else if (link_text && link_address) {
        return (
          <SectionLink
            linkText={link_text}
            linkPath={link_address}
            key={index}
          />
        )
      } else if (header && left_paragraph && right_paragraph) {
        return (
          <TextColumns
            title={header}
            leftParagraph={left_paragraph}
            rightParagraph={right_paragraph}
            key={index}
          />
        )
      } else if (headline && text && icon) {
        // merge pageFeatureImages
        image = (pageFeatureImages409 && pageFeatureImages409[index][0]) || null
        return (
          <SectionTextWithCircle
            tag={label}
            title={headline}
            text={text}
            icon={icon}
            imageSharp={get(image, "localFile.childImageSharp")}
            key={index}
          />
        )
      } else if (headline && icon) {
        // merge pageFeatureImages
        image = (pageFeatureImages409 && pageFeatureImages409[index][0]) || null
        return (
          <SectionTextWithCircle
            tag={label}
            title={headline}
            text={''}
            icon={icon}
            imageSharp={get(image, "localFile.childImageSharp")}
            key={index}
          />
        )
      } else if (label && title && text) {
        return (
          <SectionAbout label={label} title={title} text={text} key={index} />
        )
      } else if (label && text && background) {
        // merge pageFeatureImages
        background =
          (pageFeatureImagesFluid && pageFeatureImagesFluid[index][0]) || null
        return (
          <SectionFullBleed
            tag={label}
            title={text}
            imageSharp={get(background, "localFile.childImageSharp")}
            key={index}
          />
        )
      } else if (title && description && achievement_home_page_sections) {
        // merge pageFeatureImages
        achievement_home_page_sections.forEach((item, itemIndex) => {
          item.cover_image =
            (pageFeatureImages409 && pageFeatureImages409[index][itemIndex]) ||
            null
        })
        return (
          <SectionAchievements
            label={title}
            title={description}
            achievements={achievement_home_page_sections}
            key={index}
          />
        )
      } else if (
        title &&
        text &&
        column &&
        call_to_action_button_text &&
        call_to_action_button_link_address
      ) {
        // merge pageFeatureImages
        column.forEach((item, itemIndex) => {
          item.image =
            (pageFeatureImages323 && pageFeatureImages323[index][itemIndex]) ||
            null
        })
        return (
          <SectionMoreFunding
            title={title}
            subtitle={text}
            areas={column}
            linkText={call_to_action_button_text}
            linkPath={call_to_action_button_link_address}
            key={index}
          />
        )
      } else if (
        title &&
        title.toLowerCase().includes("resource") &&
        paragraph &&
        call_to_action_button_text &&
        call_to_action_link
      ) {
        return <SectionResources title={title} text={paragraph} key={index} />
      } else if (title && title.toLowerCase().includes("blog")) {
        return <SectionBlog key={index} />
      } else if (title && text && call_to_action_button_text && image) {
        return (
          <SectionNewsletter
            title={title}
            text={text}
            buttonText={call_to_action_button_text}
            image={image}
            key={index}
          />
        )
      } else if (title && supporter) {
        return (
          <SectionSupporters title={title} supporters={supporter} key={index} />
        )
      } else if (features?.length) {
        return <SectionValues title={title} values={features} key={index} />
      } else if (content && content.length) {
        return  (
          <BaseContainer>
            <PageContent dangerouslySetInnerHTML={{ __html: parseContent(content) }} key={index} />
          </BaseContainer>
        )
      } else {
        return null
      }
    }
  )

  useEffect(() => {
    setObserved(true)
  }, [])

  const isHomepage = title === "Homepage"
  const isProgramPage = !!pageFeature[0].learn_more_info
  const programs = isProgramPage
    ? pageFeature.map(({ icon, title, text, learn_more_info }) => {
        return {
          icon,
          title,
          subtitle: text,
          text: learn_more_info,
        }
      })
    : null

  return (
    <>
      <Seo
        title={metaTitle || title}
        description={metaDescription}
        image={metaImage}
      />
      {headerParagraph ? (
        <>
          {isHomepage ? (
            <Hero
              title={headerParagraph}
              imageSharp={get(headerImage, "localFile.childImageSharp")}
              buttonText="Learn more"
            />
          ) : (
            <SectionFullBleed
              primaryTitle={title}
              title={headerParagraph}
              imageSharp={get(headerImage, "localFile.childImageSharp")}
            />
          )}

          {components}
        </>
      ) : (
        <Wrapper className={observed ? "-observed" : ""}>
          <BaseContainer>
            <Title as="h1">{title}</Title>
            {isProgramPage ? (
              <ProgramList programs={programs} />
            ) : (
              { components }
            )}
          </BaseContainer>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  margin-bottom: 90px;
  padding-top: 180px;

  ${media.tablet} {
    margin-bottom: 155px;
    padding-top: 250px;
  }
`

const TitleNoSpacingBelow = styled(PrimaryTitle)`
  margin-bottom: 0px;
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 110px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  ${Wrapper}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 180px;
  }
`

const Paragraph = styled.p`
  + p {
    margin-top: 1em;
  }
`

const ButtonContainer = styled(BaseContainer)`
  text-align: center;
  margin-bottom: 90px;
  margin-top: 30px;
`

const ParagraphContainer = styled(BaseContainer)`
  padding: 30px 60px 30px 60px;
  word-break: break-word;
`

const TitleContainer = styled(BaseContainer)`
  padding: 30px 60px 40px 60px;
  text-align: center;
`

const ListContainer = styled(BaseContainer)`
  padding-left: 60px;
`

const Bold = styled.b``

const List = styled.ul`
  list-style: inside;
`

const ListItem = styled.li`
  margin-top: 1em;
`

const PageContent = styled(BaseContent)`
  padding-bottom: 60px;
`

export default Page
