import { keyframes } from "styled-components"

const PopOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(.6);
  }
  20% {
    opacity: 1;
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
`

const PopOutBig = keyframes`
  0% {
    opacity: 0;
    transform: scale(.6);
  }
  20% {
    opacity: 1;
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
`

export { PopOut, PopOutBig }
