import React, { useState, useRef } from "react"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"
import { TertiaryTitle } from "src/components/Typography"
import { Link } from "gatsby"

const FeatureList = ({ list }) => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? " -observed" : ""}>
      {list.map(({ title, text, icon, link_text, link_url }, index) => {
        let fancyLink
        if (link_text && link_url) {
          fancyLink = (
            <div>
              <br />
              <StyledLink to={link_url}>
                {link_text}
                <IconLink name="arrow-right" />
              </StyledLink>
            </div>
          )
        }
        const featureText =
          typeof text === "string" ? (
            <FeatureText>
              {text}
              {fancyLink}
            </FeatureText>
          ) : (
            <FeatureTextWrapper>
              {text &&
                text.map((paragraph, pIndex) => (
                  <FeatureText key={pIndex}>
                    {paragraph}
                    {fancyLink}
                  </FeatureText>
                ))}
            </FeatureTextWrapper>
          )
        return (
          <Feature key={index}>
            <FeatureNumber>
              {(icon && <Icon name={icon} source="feather" />) || index + 1}
            </FeatureNumber>
            <FeatureTitle dangerouslySetInnerHTML={{ __html: title }} />
            {featureText}
          </Feature>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }
`

const Feature = styled.div`
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  text-align: center;

  ${media.tablet} {
    width: calc(50% - 30px);
    text-align: left;
  }

  ${media.desktop} {
    padding-left: 90px;
  }
`

const FeatureNumber = styled.span`
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  position: relative;
  background-image: linear-gradient(
    -20deg,
    rgba(58, 182, 218, 0.07),
    rgba(80, 217, 214, 0.07)
  );
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 60px;
  color: #46cad8;

  ${media.desktop} {
    position: absolute;
    top: -15px;
    left: 0;
  }
`

const Icon = styled(BaseIcon)`
  position: absolute;
  top: 18px;
  left: 18px;
  color: #46cad8;
`

const FeatureTitle = styled(TertiaryTitle)`
  ${media.desktopMedium} {
    /* white-space: nowrap; */
  }
`

const FeatureTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`

const FeatureText = styled.p`
  max-width: 390px;
  text-align: left;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};
`

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  /* font-weight: 500; */
  color: #076262;
  transition: color 0.25s;

  &::before {
    content: "";
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -5px;
    background: ${(p) => p.theme.color.primary};
    pointer-events: none;
    transition: width 0.25s;
  }

  &:hover {
    &::before {
      width: 20px;
    }
  }
`

const IconLink = styled(BaseIcon)`
  width: 13px;
  height: 13px;
  margin-top: -1px;
  margin-left: 17px;
  transition: 0.25s;

  ${StyledLink}:hover & {
    transform: translateX(3px);
  }
`

export default FeatureList
