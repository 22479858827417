import React, { useState, useRef } from "react"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import { PrimaryTitle } from "src/components/Typography"
import FeatureList from "src/components/FeatureList"

const SectionValues = ({ title, values }) => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? " -observed" : ""}>
      <BaseContainer>
        <Title>{title}</Title>
        <FeatureList list={values} />
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 10px;
  margin-bottom: 10px;

  ${media.tablet} {
    margin-bottom: 15px;
  }
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 70px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  ${Wrapper}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 110px;
  }
`

export default SectionValues
