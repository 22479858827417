import React, { useState, useRef } from "react"
// import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"
import Circle from "src/components/Circle"
import bg from "./bg.png"

const circleSize = 133
const animationduration = "250s" // unused

const nodes = [
  {
    title: "Videos",
    size: 90,
    top: -250,
    left: 15,
    lineWidth: 200,
    lineRotate: -73,
    imageUrl: "/images/resources/videos.jpg",
    icon: "video",
    labelWidth: 100,
  },
  {
    title: "Webinars",
    size: 90,
    top: 155,
    left: -106,
    lineWidth: 200,
    lineRotate: 107,
    imageUrl: "/images/resources/webinars.jpg",
    icon: "play",
    labelWidth: 120,
  },
  {
    title: "Templates",
    size: 70,
    top: -84,
    left: 172,
    lineWidth: 200,
    lineRotate: -13,
    imageUrl: "/images/resources/templates.jpg",
    icon: "edit",
    labelWidth: 130,
  },
  {
    title: "Press releases",
    size: 80,
    top: 9,
    left: -246,
    lineWidth: 200,
    lineRotate: 167,
    imageUrl: "/images/resources/press-releases.jpg",
    icon: "file-text",
    labelWidth: 155,
  },
  {
    title: "Leaflets",
    size: 80,
    top: 113,
    left: 102,
    lineWidth: 200,
    lineRotate: 47,
    imageUrl: "/images/resources/leaflets.jpg",
    icon: "book-open",
    labelWidth: 107,
  },
  {
    title: "Podcasts",
    size: 90,
    top: -197,
    left: -187,
    lineWidth: 200,
    lineRotate: 227,
    imageUrl: "/images/resources/podcasts.jpg",
    icon: "mic",
    labelWidth: 118,
  },
]

const SectionResourcesIllustration = () => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      } else {
        // setObserved(false)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? "-observed" : ""}>
      <Nodes>
        {nodes.map(
          (
            {
              title,
              size,
              top,
              left,
              lineWidth,
              lineRotate,
              imageUrl,
              icon,
              labelWidth,
            },
            index
          ) => (
            <Node key={index}>
              <Line rotate={lineRotate}>
                <LineShape x1="0" x2={lineWidth} />
              </Line>
              <ImageWrap top={top} left={left}>
                <Image width={size} src={imageUrl} loading="lazy" alt={title} />
                <Label>
                  <LabelIconWrap labelWidth={labelWidth}>
                    <LabelIcon name={icon} source="feather" />
                    <LabelText>{title}</LabelText>
                  </LabelIconWrap>
                </Label>
              </ImageWrap>
            </Node>
          )
        )}
      </Nodes>
      <CircleWrap>
        <StyledCircle size={circleSize} />
        <CircleIcon name="exchange" />
      </CircleWrap>
    </Wrapper>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 399px;
  height: 399px;
  margin-bottom: -50px;
  position: relative;
  top: 40px;
  left: 40px;
  transform: scale(0.5);
  transform-origin: 0% 0%;

  &::before {
    content: "";
    width: 589px;
    height: 636px;
    position: absolute;
    top: -100px;
    left: -100px;
    background: url(${bg}) 0 0 / 100% no-repeat;
  }

  @media (min-width: 330px) {
    margin-bottom: 0;
    transform: scale(0.62);
  }

  @media (min-width: 400px) {
    margin-bottom: 30px;
    transform: scale(0.7);
  }

  ${media.tablet} {
    margin-bottom: 0;
    transform: scale(0.67);
    transform-origin: 0 50%;
  }

  ${media.desktop} {
    left: 30px;
    transform: none;
    transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

    &:not(.-observed) {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  ${media.desktopMedium} {
    left: 0;
  }
`

const Nodes = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  /* animation: ${rotate} ${animationduration} linear infinite; */
`

const Node = styled.div``

const Line = styled.svg`
  width: 400px;
  max-width: none;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(${(p) => p.rotate}deg);
  transform-origin: 0 0;
`

const LineShape = styled.line`
  stroke: #81e2cc;
  stroke-width: 4;
  stroke-dasharray: 8 4;
`

// const ImageWrap = styled(Link)`
const ImageWrap = styled.span`
  position: absolute;
  left: 0;
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
  /* animation: ${rotate} ${animationduration} linear infinite reverse; */
`

const Image = styled.img`
  max-width: none;
  border-radius: 50%;
  transition: transform 0.25s;

  ${ImageWrap}:hover & {
    transform: scale(1.07);
  }
`

const Label = styled.div``

const LabelIconWrap = styled.div`
  width: 44px;
  height: 44px;
  position: absolute;
  right: -15px;
  bottom: -15px;

  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: #fff;
    background: #fff;
    border-radius: 22px;
    box-shadow: 0 16px 16px rgba(0, 11, 33, 0.08);
    transition: width 0.15s;
  }

  ${ImageWrap}:hover & {
    &::before {
      width: ${(p) => p.labelWidth + 20}px;
    }
  }
`

const LabelIcon = styled(BaseIcon)`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #46cad8;
`

const LabelText = styled.p`
  visibility: visible;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 50px;
  transform: translateX(-5px);
  font-size: 15px;
  line-height: 44px;
  white-space: nowrap;
  color: ${(p) => p.theme.color.textLight};
  /* color: #076262; */
  transition: 0.15s;

  ${ImageWrap}:hover & {
    visibility: visible;
    opacity: 1;
    transform: none;
  }
`

const CircleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${circleSize}px;
  height: ${circleSize}px;
`

const StyledCircle = styled(Circle)`
  position: absolute;

  svg {
    border-radius: 50%;
  }
`

const CircleIcon = styled(BaseIcon)`
  width: 46px;
  height: 46px;
  position: relative;
  color: #46cad8;
`

export default SectionResourcesIllustration
