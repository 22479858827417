import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import { Statement } from "src/components/Typography"

const SectionStatement = ({ text, greyText, linkText, linkPath }) => {
  const [observed, setObserved] = useState(false)
  const wrapper = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: wrapper,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper ref={wrapper} className={observed ? "-observed" : ""}>
      <Container>
        <Statement>
          {text} <small>{greyText}</small>
        </Statement>
        {linkText && linkPath && (
          <Cta>
            <StyledLink to={linkPath}>
              {linkText}
              <Icon name="arrow-right" />
            </StyledLink>
          </Cta>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 100px;

  ${media.tablet} {
    margin-bottom: 150px;
  }

  ${media.desktop} {
    margin-bottom: 165px;
  }
`

const Container = styled(BaseContainer)`
  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    ${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }
`

const Cta = styled.p`
  margin-top: 35px;
`

const StyledLink = styled(Link)`
  display: inline-block;
  position: relative;
  /* font-weight: 500; */
  color: #076262;
  transition: color 0.25s;

  &::before {
    content: "";
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -5px;
    background: ${(p) => p.theme.color.primary};
    pointer-events: none;
    transition: width 0.25s;
  }

  &:hover {
    &::before {
      width: 20px;
    }
  }
`

const Icon = styled(BaseIcon)`
  width: 13px;
  height: 13px;
  margin-top: -1px;
  margin-left: 17px;
  transition: 0.25s;

  ${StyledLink}:hover & {
    transform: translateX(3px);
  }
`

export default SectionStatement
