import React from "react"

const BaseFlagIcon = ({ country, className }) => {
  if (!country) return null
  if (country === "united-kingdom") {
    country = "uk"
  }
  if (country === "anima-international") {
    return null
  }
  const src = require(`src/assets/icons/flags/${country}.svg`)?.default
  return <img src={src} className={className} alt={country} />
}

export default BaseFlagIcon
