import styled, { css } from "styled-components"
import { media } from "src/styles/breakpoints"

export default styled.div(({ narrow, wide }) => {
  return css`
    h2 {
      margin-top: 60px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 23px;
      line-height: 1.43;
      letter-spacing: -0.01em;
      font-family: ${(p) => p.theme.font.primary};
      color: #000;

      ${media.tablet} {
        font-size: 28px;
      }
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      font-family: ${(p) => p.theme.font.primary};
      color: #000;

      ${media.tablet} {
        font-size: 21px;
      }
    }

    h4 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: 500;
      font-family: ${(p) => p.theme.font.primary};
      color: #000;
    }

    p {
      margin-bottom: 20px;

      strong {
        /* font-weight: 600; */
        color: #000;
      }

      i {
        font-style: italic;
        font-size: 16px;
        font-family: Georgia, serif;
      }

      ${media.tablet} {
        i {
          font-size: 19px;
        }
      }
    }

    ul, ol {
      margin-bottom: 2em;

      li {
        position: relative;
        padding-left: 40px;
    
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          position: absolute;
          top: 14px;
          left: 20px;
          background: ${(p) => p.theme.color.primary};
          border-radius: 50%;
        }
      }
    }

    blockquote {
      margin: 2em 0 2em 60px;
      position: relative;
    
      &::before {
        content: "”";
        opacity: 0.1;
        position: absolute;
        top: -16px;
        left: -65px;
        font-size: 120px;
        line-height: 1;
        font-family: "Arial";
        /* color: #f3f6f6; */
      }
    
      ${media.tablet} {
        margin: 3em 40px 3em 80px;
      }
    
      ${media.desktop} {
        margin-left: 65px;
        margin-right: 0;
      }

      p {
        font-size: ${(p) => p.theme.baseFontSize.phone};
        line-height: ${(p) => p.theme.baseLineHeight};
        font-family: ${(p) => p.theme.font.primary};
        color: ${(p) => p.theme.color.text};
      
        ${media.tablet} {
          font-size: ${(p) => p.theme.baseFontSize.desktop};
        }
      }

      footer {
        margin-top: 10px;
        font-size: 13px;
        line-height: ${(p) => p.theme.baseLineHeight};
        font-family: ${(p) => p.theme.font.primary};
        color: ${(p) => p.theme.color.textLight};

        &::before {
          content: "– ";
        }
      }
    }

    hr {
      margin-bottom: 2.5em;
      border: none;
      text-align: center;
      line-height: 1;

      &::before {
        content: "...";
        font-size: 24px;
        letter-spacing: 0.5em;
        font-family: Frank Ruhl Libre, Georgia, serif;
      }
    }

    figure {
      margin: 2em 0;

      ${media.tablet} {
        margin: 2.5em 0;
      }

      &.media {
        display: block;
        margin: 2em 0;
        padding: 0;
        position: relative;
        overflow: hidden;
        width: 100%;
  
        ${media.tablet} {
          margin: 2.5em 0;
        }
      
        &:before {
          content: "";
          display: block;
          padding-top: 56.25%;
        }
      
        iframe {
          border: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }

      img {
        display: block;
        width: 100%;
      }

      figcaption {
        margin-top: 10px;
        text-align: center;
        font-size: 13px;
        line-height: ${(p) => p.theme.baseLineHeight};
        font-family: ${(p) => p.theme.font.primary};
        color: ${(p) => p.theme.color.textLight};
      }
    }
  `
})
