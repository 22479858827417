import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import BlogPostList from "src/components/BlogPostList"
import { PrimaryTitle } from "src/components/Typography"

const SectionBlog = () => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allStrapiBlogPosts(
          limit: 3
          filter: { publish: { eq: true } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            title
            slug
            date(formatString: "MMMM D, YYYY")
            cover_image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 289
                    height: 192
                    quality: 80
                    layout: FIXED
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  const blogCms = data.allStrapiBlogPosts.nodes
  const posts = blogCms
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 3)

  return (
    <Wrapper>
      <BaseContainer>
        <Header>
          <Title>Blog</Title>
          <StyledLink to="/blog">
            All posts
            <ArrowIcon name="arrow-right" />
          </StyledLink>
        </Header>
        <BlogPostList posts={posts} />
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 170px;

  ${media.desktop} {
    margin-bottom: 215px;
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;

  ${media.tablet} {
    margin-bottom: 95px;
  }
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 0;
`

const StyledLink = styled(Link)`
  color: #076262;
`

const ArrowIcon = styled(BaseIcon)`
  width: 13px;
  height: 13px;
  margin-top: -1px;
  margin-left: 17px;
`

export default SectionBlog
